<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="inspections"
            :items-per-page="10"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>

import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import Inspection from '../../../models/Inspection';
import axios from "axios";


export default {
    name: "Residential",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            loading: false,
            users: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Inspector', value: 'inspector' },

                { text: 'Created at', value: 'created_at' },
            ],
        }
    },
    methods: {
        handleClickRow(inspection) {
            axios({
                url:  '/api/admin/inspection/residential',
                method: 'POST',
                data:{id:inspection.id},
                responseType: 'blob',

            })
                .then((response) => {

                    if(response.status == 200){

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'test.pdf');
                        document.body.appendChild(link);
                        link.click();
                        console.log(2)
                    }
                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                //Perform action in always
            });
            //   this.$router.push({name: 'admin.inspection.show', params: {id: user.id}})
        }
    },
    async mounted() {
        this.loading = true
        this.inspections = await Inspection.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
